.icon {
  display: inline-block;
  height: 20px;
  width: 20px;

  &.icon-home-line {
    background: url('/assets/icons/home-line.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  &.icon-booking {
    background: url('/assets/icons/booking.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  &.icon-crown {
    background: url('/assets/icons/crown.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  &.icon-book-reading {
    background: url('/assets/icons/book-reading.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  &.icon-art {
    background: url('/assets/icons/art.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  &.icon-skate-boarding {
    background: url('/assets/icons/skate-boarding.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  &.icon-social-personal {
    background: url('/assets/icons/social-icon-personal.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  &.icon-social-facebook {
    background: url('/assets/icons/social-icon-facebook.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  &.icon-social-instagram {
    background: url('/assets/icons/social-icon-instagram.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  &.icon-social-linkedin {
    background: url('/assets/icons/social-icon-linkedin.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  &.icon-social-tiktok {
    background: url('/assets/icons/social-icon-tiktok.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  &.icon-plus {
    background: url('/assets/icons/plus.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  &.icon-add-story {
    background: url('/assets/icons/edit-03.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  &.icon-gift {
    background: url('/assets/icons/gift-02.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  &.icon-dots-horizontal {
    background: url('/assets/icons/dots-horizontal.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  &.icon-address {
    background: url('/assets/icons/address-gray.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  &.icon-university-head {
    background: url('/assets/icons/university-head.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  &.icon-heart-gray {
    background: url('/assets/icons/heart-gray-400.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  &.icon-bio {
    background: url('/assets/icons/bio.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  &.icon-home-town {
    background: url('/assets/icons/home-town.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  &.icon-phone {
    background: url('/assets/icons/phone.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  &.icon-coin {
    background: url('/assets/icons/coin.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }
  &.icon-emoji {
    background: url('/assets/images/icon/emoji.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }
  &.icon-location {
    background: url('/assets/images/icon/location.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }
  &.icon-tag {
    background: url('/assets/images/icon/tag.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }
  &.icon-social-twitter {
    background: url('/assets/icons/social-icon-twitter.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }
}
