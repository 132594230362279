// Global PrimeNG custom styles
@import '_mixins.scss';

.p-inputswitch {
  .p-inputswitch-slider {
    @apply rounded-[30px];
    &:before {
      @apply rounded-[50%];
    }
  }
}

lib-ngx-photo-editor {
  .ngx-pe-dialog-header {
    @apply bg-palette-base-white text-palette-base-black;
  }

  .ngx-pe-tool-bar {
    @apply bg-palette-base-white border-b-2 py-3;
    button {
      &:hover {
        @apply bg-transparent;
      }
      svg {
        @apply fill-palette-gray-600;
      }
    }
  }

  .ngx-pe-dialog-footer {
    @apply bg-palette-base-white;
    .ngx-pe-btn {
      @apply text-base py-2 rounded-lg;
      &.ngx-pe-btn-outline-light {
        @apply border border-palette-gray-200 text-palette-gray-700;
      }
    }
  }
}

.btn-primary-500-xs {
  @apply border-branding-primary-500 bg-branding-primary-500 text-xs text-palette-base-white;
}

.btn-primary-600-xs {
  @apply border-branding-primary-600 bg-branding-primary-600 text-xs text-palette-base-white;
}

.btn-primary-700-xs {
  @apply border-branding-primary-700 bg-branding-primary-700 text-xs text-palette-base-white;
}

.btn-header-tab {
  @apply py-2 px-4 rounded-lg text-base font-semibold;
  &.selected {
    @apply text-palette-blue-700 bg-branding-primary-50;
  }
  &.unselected {
    @apply text-palette-gray-500 bg-transparent;
  }
}

.badges-border {
  &.p-button.p-button-outlined {
    @apply text-transparent p-[1px];
  }
  border-image-slice: 1 !important;
  @apply font-medium border border-solid;
  .badges-border-child {
    @apply py-[3px] px-[8px] bg-white rounded-full;
  }
}

.badges-backpacker {
  @apply backpacker backpacker-text;
}

.badges-voyager {
  @apply voyager voyager-text;
}

.badges-trailblazer {
  @apply trailblazer trailblazer-text;
}

.badges-expert-explorer {
  @apply expert-explorer expert-explorer-text;
}

.badges-adventurer {
  @apply adventurer adventurer-text;
}

.badges-rookie {
  @apply rookie rookie-text;
}

.badges-daytripper {
  @apply daytripper daytripper-text;
}

.badges-globetrotter {
  @apply globetrotter globetrotter-text;
}
