body {
  @apply bg-palette-gray-100;
}

body,
body .p-component {
  @apply font-inter;
}

.p-button {
  &:focus {
    @apply shadow-none;
  }
  &:enabled {
    &:hover {
      @apply border-branding-primary-600;
    }
  }
  @apply rounded-lg border-branding-primary-500 px-[16px] py-[10px] text-sm;

  &.p-button-success {
    &:enabled {
      &:hover {
        @apply border-system-success-600 bg-system-success-600;
      }
    }
    @apply border-system-success-500 bg-system-success-500;
  }

  &.p-button-info {
    &:enabled {
      &:hover {
        @apply border-system-info-600 bg-system-info-600;
      }
    }
    @apply border-system-info-500 bg-system-info-500;
  }

  &.p-button-warning {
    &:enabled {
      &:hover {
        @apply border-system-warning-600 bg-system-warning-600 text-palette-base-white;
      }
    }
    @apply border-system-warning-500 bg-system-warning-500 text-palette-base-white;
  }

  &.p-button-danger {
    &:enabled {
      &:hover {
        @apply border-system-error-600 bg-system-error-600;
      }
    }
    @apply border-system-error-500 bg-system-error-500;
  }
}
