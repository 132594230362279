/* You can add global styles to this file, and also import other style files */

@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  @apply w-[6px];
}

::-webkit-scrollbar-track {
  @apply bg-palette-gray-100 rounded-[3px];
}

::-webkit-scrollbar-thumb {
  @apply bg-palette-gray-300 rounded-[3px];
}

::-webkit-scrollbar-thumb:hover {
  @apply bg-palette-gray-400 cursor-pointer;
}
// .app-container {
//   @include container();
// }
// html {
//   scrollbar-gutter: stable;
// }

// body {
//   width: 100%;
// }

// .no-editor {
//   .p-editor-toolbar.ql-toolbar.ql-snow.ng-star-inserted {
//     display: none;
//   }
//   .ql-toolbar.ql-snow + .ql-container.ql-snow {
//     border-left: 0px;
//     border-right: 0px;
//     border-width: 0px;
//   }
//   .ql-editor {
//     padding: 0px 3px;
//     font-size: 0.875rem;
//     font-family: Inter, sans-serif;
//   }
// }

@import "styles/fonts";
@import "styles/theme";

@import "~primeicons/primeicons.css";
@import "~primeng/resources/primeng.min.css";
@import "styles/primeng-custom";
@import "styles/variables";
@import "styles/mixins";
@import "styles/svg";
@import "@soctrip-common/icon/soctrip-icon.css";
// @import '~quill/dist/quill.snow.css';
// @import '@ctrl/ngx-emoji-mart/picker';
