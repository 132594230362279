@import 'variables.scss';

@mixin center() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin container() {
  padding: 0 $container-x-padding;
}
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

.backpacker {
  background-image: linear-gradient(142.78deg, #9fccfa -22.92%, #0974f1 122.49%);
  @apply rounded-full;
}

.backpacker-text {
  background: linear-gradient(142.78deg, #9fccfa -22.92%, #0974f1 122.49%);
  @apply bg-clip-text text-transparent;
}

.voyager {
  background-image: linear-gradient(180deg, #1d976c 0%, #93f9b9 100%);
  @apply rounded-full;
}

.voyager-text {
  background: linear-gradient(180deg, #1d976c 0%, #93f9b9 100%);
  @apply bg-clip-text text-transparent;
}

.trailblazer {
  background-image: linear-gradient(180deg, #1d976c 0%, #93f9b9 100%);
  @apply rounded-full;
}

.trailblazer-text {
  background: linear-gradient(180deg, #ffe29f 0%, #ffa99f 39.58%, #ff719a 100%);
  @apply bg-clip-text text-transparent;
}

.expert-explorer {
  background-image: linear-gradient(161.15deg, #ffa492 12.73%, #ff2c55 72.95%);
  @apply rounded-full;
}

.expert-explorer-text {
  background: linear-gradient(161.15deg, #ffa492 12.73%, #ff2c55 72.95%);
  padding:
    8px,
    6px,
    8px 6px;
  @apply bg-clip-text text-transparent;
}

.adventurer {
  background-image: linear-gradient(135deg, #ec2f2f 14.73%, #dd2fec 100%);
  @apply rounded-full;
}

.adventurer-text {
  background: linear-gradient(135deg, #ec2f2f 14.73%, #dd2fec 100%);
  @apply bg-clip-text text-transparent;
}

.rookie {
  background-image: linear-gradient(297.24deg, #8fd3f4 8.25%, #84fab0 91.76%);
  @apply rounded-full;
}

.rookie-text {
  background: linear-gradient(297.24deg, #8fd3f4 8.25%, #84fab0 91.76%);
  @apply bg-clip-text text-transparent;
}

.daytripper {
  background-image: linear-gradient(142.29deg, #cf9091 21.8%, #c72370 73.22%);
  @apply rounded-full;
}

.daytripper-text {
  background: linear-gradient(142.29deg, #cf9091 21.8%, #c72370 73.22%);
  @apply bg-clip-text text-transparent;
}

.globetrotter {
  background-image: linear-gradient(0deg, #7028e4 0%, #e5b2ca 100%);
  @apply rounded-full;
}

.globetrotter-text {
  background: linear-gradient(0deg, #7028e4 0%, #e5b2ca 100%);
  @apply bg-clip-text text-transparent;
}
